import { getFQCN } from "@/lib/helpers/fqcn/methods";
import { useLocale, useTranslations } from "next-intl";
import Link from "next/link";
import React from "react";
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from "./card";
import { Button } from "@/components/ui/button";

const fqcn_bui = {
  Bundle: "cardListingBundle",
  Unit: "cardListing",
  Interface: "cardListingInterface",
};

type CardListingProps = {
  arrayNav: {
    title: string;
    desc: string;
    link: string;
    icon: JSX.Element;
    id: string;
  }[];
};

const CardListing: React.FC<CardListingProps> = ({ arrayNav }) => {
  const t = useTranslations("CardListing");
  const locale = useLocale();

  return (
    <main id={getFQCN(fqcn_bui, "main-landing")} className="dark:bg-dark-mode">
      <article className="max-w-screen flex flex-col min-h-screen h-full justify-between items-center">
        <div
          className={`pt-12 grid gap-8 md:p-12 justify-center place-items-center ${
            arrayNav.length === 1 ? "grid-cols-1" : "grid-cols-1 md:grid-cols-3"
          }`}
        >
          {arrayNav.map((item) => (
            <div className="flex w-60 h-64" key={arrayNav.indexOf(item)}>
              <Link
                href={
                  item.link.includes("http") // If the link is an external link
                    ? item.link
                    : locale === "fr"
                    ? `/fr/${item.link}`
                    : `/en/${item.link}`
                }
              >
                <div className="flex w-60 h-64">
                  <Card
                    id={getFQCN(fqcn_bui, item.id + "-card")}
                    className="text-center border-gray-300 hover:border-blue-400 transform hover:scale-[1.02] transition-all ease-linear"
                  >
                    <CardHeader
                      id={getFQCN(fqcn_bui, item.id + "-cardHeader")}
                      className="flex justify-center items-center"
                    >
                      {item.icon}
                    </CardHeader>
                    <CardTitle id={getFQCN(fqcn_bui, item.id + "-cardTitle")}>
                      {" "}
                      {t(item.title)}
                    </CardTitle>
                    <CardContent
                      id={getFQCN(fqcn_bui, item.id + "-cardContent")}
                      className="text-sm p-3"
                    >
                      {t(item.desc)}
                    </CardContent>
                    <CardFooter
                      id={getFQCN(fqcn_bui, item.id + "-cardFooter")}
                      className="flex justify-center items-center"
                    >
                      <div className="fixed bottom-0 mb-4">
                        <Button className="bg-phpc-blue dark:bg-phpc-blue-dark hover:bg-phpc-blue-dark dark:hover:bg-phpc-blue text-white">
                          {t("Visit")}
                        </Button>
                      </div>
                    </CardFooter>
                  </Card>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </article>
    </main>
  );
};

export default CardListing;
